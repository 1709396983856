import { useEffect, useRef, useContext } from "react";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";

const LogoutTimer = () => {
  const logoutTimer = useRef(null);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const user = useContext(UserContext);

  const handleLoutFunction = () => {
    toast.success("You have been logout successfully!");
    window.localStorage.removeItem("outToken");
    window.localStorage.removeItem("clientToken");
    window.localStorage.removeItem("arbitage");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("ConnectWallet");
    user.userLogIn(false, null, null);
    // setIsLogout(false);
    history.push("/");
  };

  const logout = () => {
    toast.error("Session expired!");
    handleLoutFunction();
  };
  const resetTimer = () => {
    clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(logout, 2 * 60 * 60 * 1000); // 2 hours in milliseconds
  };
  const handleUserActivity = () => {
    resetTimer();
  };
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      resetTimer();
      const events = [
        "mousedown",
        "keydown",
        "mousemove",
        "scroll",
        "touchstart",
      ];
      const attachEventListeners = () => {
        events.forEach((event) => {
          window.addEventListener(event, handleUserActivity);
        });
      };
      attachEventListeners();
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, handleUserActivity);
        });
        clearTimeout(logoutTimer.current);
      };
    }
  }, []);

  return null;
};
export default LogoutTimer;
